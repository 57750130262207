import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ReviewCard from "../components/review-card";
import TeamImageSection from "../components/team-image-section";
import ReviewCardProf from "../components/review-card-prof";

const IndexPage = () => {
	const responsive = {
		0: {
			items: 1,
		},
		767: {
			items: 2,
		},
		991: {
			items: 3,
		},
		1200: {
			items: 4,
		},
	};

	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Image-Latest" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			earlyImg1: wpMediaItem(title: { eq: "Abstract" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			earlyImg2: wpMediaItem(title: { eq: "Early-Therapy-2" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			pImg: wpMediaItem(title: { eq: "Homepage-Image-Holder" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			vidImg: wpMediaItem(title: { eq: "Home-Hero-New" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpTestimonial {
				nodes {
					testimonialFeilds {
						name
						professional
						review
						jobTitle
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
					}
				}
			}
			profReviews: allWpTestimonial(
				filter: { testimonialFeilds: { professional: { eq: true } } }
			) {
				nodes {
					testimonialFeilds {
						name
						professional
						review
						jobTitle
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const pluginImage1 = getImage(
		data.earlyImg1.localFile.childImageSharp.gatsbyImageData
	);
	const pluginImage2 = getImage(
		data.earlyImg2.localFile.childImageSharp.gatsbyImageData
	);
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	const patients = data.allWpTestimonial.nodes
		.filter((item, index) => item.testimonialFeilds.professional !== true)
		.map((review) => (
			<Col className="mx-4 mb-5 mt-6">
				<ReviewCard review={review} />
			</Col>
		));

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Facial Palsy Experts | The Facial Rehabilitation Centre"
				description="We are expert facial therapists passionate about providing a research based and individually tailored facial rehabilitation programme to help our patients overcome facial palsy."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Home | The Facial Rehabilitation Centre",
					description:
						"We are expert facial therapists passionate about providing a research based and individually tailored facial rehabilitation programme to help our patients overcome facial palsy.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="py-5 py-lg-7 position-relative">
					<div
						style={{ opacity: 0.73, zIndex: -2 }}
						className="bg-primary position-absolute top-0 start-0 w-100 h-100"
					></div>
					<Container>
						<Row className="align-items-center">
							<Col className="text-white" lg={6}>
								<h1 className="pb-4">
									Welcome to The Facial Rehabilitation Centre
								</h1>
								<p className=" fs-5">
									We are expert facial therapists passionate about providing a
									research based and individually tailored facial rehabilitation
									programme to help our patients overcome facial palsy. We will
									work alongside you throughout your rehabilitation journey to
									maximise your recovery and achieve your personal
									rehabilitation goals.
								</p>
								<Button
									as={Link}
									to="/contact-us"
									className="px-3 w-100 w-md-auto me-md-4 mt-3"
									variant="primary"
								>
									{" "}
									Book appointment
								</Button>
								<Button
									as={Link}
									to="/what-to-expect"
									className="px-3 me-md-4 w-100 w-md-auto text-white mt-3"
									variant="secondary"
								>
									{" "}
									What to expect
								</Button>
							</Col>
							<Col
								xs={{ span: 12, order: "first" }}
								lg={{ span: 6, order: "last" }}
							>
								<div
									className="mb-5 pb-lg-0"
									style={{
										borderRadius: "10px",
										overflow: "hidden",
									}}
								>
									<GatsbyImage
										image={
											data.heroImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.heroImg.alt}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="text-dark-bg pb-4">
									Providing the best expertise for your recovery
								</h2>
								<p>
									Welcome to the Facial Rehabilitation Centre. Our team of
									expert facial therapists are here to offer you rehabilitation
									and guidance at any stage as you overcome facial palsy.{" "}
								</p>
								<p>
									We treat clients of all ages from all over the world either
									face to face, or online. We specialise in the management of
									all facial palsy patients from acute care through to the
									management and treatment of synkinesis. We also offer pre and
									post-surgical management.{" "}
								</p>
								<p>
									Commencing appropriate facial rehabilitation as soon as
									possible following the onset of your symptoms will ensure that
									you have the correct knowledge and understanding of your
									condition and know what to do to get the best possible
									recovery. It does not matter how long you have had facial
									palsy; we can help you at any stage of recovery. We also offer
									botulinum toxin injections as part of a facial rehabilitation
									programme.{" "}
								</p>
								<p>
									We have worked together as part of the specialist facial palsy
									multidisciplinary team at Queen Victoria NHS Foundation Trust
									for many years and are delighted to work together in The
									Facial Rehabilitation Centre. We are grateful to be able to
									offer timely access to expert facial therapy for everyone in
									need. We also offer expert help for overseas patients who may
									not have access to specialist services locally. Sadly NHS
									waiting times for specialist facial care are currently very
									long therefore we are happy to offer assessment and treatment
									for UK based patients whilst they are on the waiting list for
									specialist NHS services.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="text-center pb-3 pb-lg-7">
								<h2 className="text-dark-bg pb-4">Our patient testimonials</h2>
								{/* <p>
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore fugiat sunt culpa officia deserunt mollit anim
									est laborum.
								</p> */}
							</Col>
						</Row>
						<Row className="h-100">
							<AliceCarousel
								style={{ minHeight: "100%" }}
								infinite={true}
								responsive={responsive}
								mouseTracking
								items={patients}
								disableDotsControls
								autoPlayInterval={2000}
							/>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="text-center pb-3 pb-lg-7">
								<h2 className="text-dark-bg pb-4">Our team</h2>
								{/* <p>
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore fugiat sunt culpa officia deserunt mollit anim
									est laborum.
								</p> */}
							</Col>
						</Row>

						<TeamImageSection />
					</Container>
				</section>
				<section className="pb-5 pb-lg-0">
					<Container>
						<Row className="align-items-center ">
							<Col lg={5} className="  mt-3 mt-lg-0">
								<h2 className="text-dark-bg pb-4">Benefits of early therapy</h2>
								<p>
									There are many benefits to starting facial therapy early. When
									your nerve is not working or slowly recovering, the face
									quickly develops incorrect movement habits. These can cause
									long term problems, in particular synkinesis.
								</p>
								<p>
									{" "}
									Research shows that early facial therapy can minimise the risk
									of synkinesis and improve your facial movement from a much
									earlier stage of recovery.
								</p>
								<Button
									as={Link}
									to="/specialist-facial-therapy"
									className="px-3 w-100 w-md-auto me-md-4 mt-3"
									variant="primary"
								>
									{" "}
									Find out more
								</Button>
							</Col>
							<Col
								xs={{ span: 12, order: "first" }}
								lg={{ span: 7, order: "last" }}
							>
								<Row>
									<Col className="text-center ">
										<GatsbyImage
											image={
												data.earlyImg1.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.earlyImg1.altText}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="text-center ">
								<h2 className="text-dark-bg pb-4">
									Who we accept referrals from
								</h2>
								<p>
									At the facial rehabilitation centre, we accept referrals from
									all over the UK and internationally from patient
									self-referrals, consultants, and GP's. We see patients who
									have a lower motor neuron facial nerve palsy, such as those
									patients diagnosed with Bell’s palsy, Ramsey hunt syndrome, or
									after surgical procedures to name but a few.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="py-5 pt-lg-7 bg-primary">
					<Container>
						<Row>
							<Col className="text-center pb-5 ">
								<h2 className="text-white pb-4">What Type Of Patients</h2>
								<p className="text-white">
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore fugiat sunt culpa officia deserunt mollit anim
									est laborum.
								</p>
							</Col>
						</Row>
						<Row className="g-6">
							<Col className="text-center" md={6} lg={3}>
								<div
									className="mb-4"
									style={{ borderRadius: "15px", overflow: "hidden" }}
								>
									<GatsbyImage
										image={data.pImg.localFile.childImageSharp.gatsbyImageData}
										alt={data.pImg.altText}
									/>
								</div>
								<h3 className="text-white fs-3">Type Patient</h3>
								<p className="text-white ubuntu-bold">Lorem ipsum docent</p>
							</Col>
							<Col className="text-center" md={6} lg={3}>
								<div
									className="mb-4"
									style={{ borderRadius: "15px", overflow: "hidden" }}
								>
									<GatsbyImage
										image={data.pImg.localFile.childImageSharp.gatsbyImageData}
										alt={data.pImg.altText}
									/>
								</div>
								<h3 className="text-white fs-3">Type Patient</h3>
								<p className="text-white ubuntu-bold">Lorem ipsum docent</p>
							</Col>
							<Col className="text-center" md={6} lg={3}>
								<div
									className="mb-4"
									style={{ borderRadius: "15px", overflow: "hidden" }}
								>
									<GatsbyImage
										image={data.pImg.localFile.childImageSharp.gatsbyImageData}
										alt={data.pImg.altText}
									/>
								</div>
								<h3 className="text-white fs-3">Type Patient</h3>
								<p className="text-white ubuntu-bold">Lorem ipsum docent</p>
							</Col>
							<Col className="text-center" md={6} lg={3}>
								<div
									className="mb-4"
									style={{ borderRadius: "15px", overflow: "hidden" }}
								>
									<GatsbyImage
										image={data.pImg.localFile.childImageSharp.gatsbyImageData}
										alt={data.pImg.altText}
									/>
								</div>
								<h3 className="text-white fs-3">Type Patient</h3>
								<p className="text-white ubuntu-bold">Lorem ipsum docent</p>
							</Col>
							<Col className="text-center" md={6} lg={3}>
								<div
									className="mb-4"
									style={{ borderRadius: "15px", overflow: "hidden" }}
								>
									<GatsbyImage
										image={data.pImg.localFile.childImageSharp.gatsbyImageData}
										alt={data.pImg.altText}
									/>
								</div>
								<h3 className="text-white fs-3">Type Patient</h3>
								<p className="text-white ubuntu-bold">Lorem ipsum docent</p>
							</Col>
							<Col className="text-center" md={6} lg={3}>
								<div
									className="mb-4"
									style={{ borderRadius: "15px", overflow: "hidden" }}
								>
									<GatsbyImage
										image={data.pImg.localFile.childImageSharp.gatsbyImageData}
										alt={data.pImg.altText}
									/>
								</div>
								<h3 className="text-white fs-3">Type Patient</h3>
								<p className="text-white ubuntu-bold">Lorem ipsum docent</p>
							</Col>
							<Col className="text-center" md={6} lg={3}>
								<div
									className="mb-4"
									style={{ borderRadius: "15px", overflow: "hidden" }}
								>
									<GatsbyImage
										image={data.pImg.localFile.childImageSharp.gatsbyImageData}
										alt={data.pImg.altText}
									/>
								</div>
								<h3 className="text-white fs-3">Type Patient</h3>
								<p className="text-white ubuntu-bold">Lorem ipsum docent</p>
							</Col>
							<Col className="text-center" md={6} lg={3}>
								<div
									className="mb-4"
									style={{ borderRadius: "15px", overflow: "hidden" }}
								>
									<GatsbyImage
										image={data.pImg.localFile.childImageSharp.gatsbyImageData}
										alt={data.pImg.altText}
									/>
								</div>
								<h3 className="text-white fs-3">Type Patient</h3>
								<p className="text-white ubuntu-bold">Lorem ipsum docent</p>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								{" "}
								<Button
									as={Link}
									to="/"
									className="px-3 me-md-4 w-100 w-md-auto text-white mt-5"
									variant="secondary"
								>
									View Profile
								</Button>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section className="py-5 py-lg-7 bg-dark-bg">
					<Container>
						<Row className="align-items-center">
							<Col lg={5} className=" text-center text-lg-start ">
								<p className="text-secondary ubuntu-medium">NHS patients</p>
								<h2 className="text-white pb-4 ">
									Happy to support those transferring to the NHS
								</h2>
								<p className="text-white">
									We are happy to take patients who are awaiting NHS services
									and happy to facilitate the transition between these two
									services.
									<br />
									<br />
									We can treat patients who require facial therapy and chemo
									denervation treatments. We have the facilities to treat
									patients virtually or in person when required.
								</p>
								<Button
									as={Link}
									to="/contact-us"
									className="px-3 me-md-4 w-100 w-md-auto text-white "
									variant="secondary"
								>
									Contact us
								</Button>
							</Col>
							<Col
								xs={{ span: 12, order: "first" }}
								lg={{ span: 7, order: "last" }}
								className="text-center mb-5 mb-lg-0"
							>
								<GatsbyImage
									image={data.vidImg.localFile.childImageSharp.gatsbyImageData}
									alt={data.vidImg.altText}
									style={{ borderRadius: "10px" }}
								/>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="py-5  py-lg-7 bg-light-grey">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8}>
								<Row>
									<Col className="">
										<h2 className="text-dark-bg text-center mb-4">
											Services We Provide
										</h2>
										<p className="text-center mb-5">
											Duis aute irure dolor in reprehenderit in voluptate velit
											esse cillum dolore fugiat sunt culpa officia deserunt
											mollit anim est laborum.
										</p>
									</Col>
								</Row>
								<Row className="g-5 g-md-0">
									<Col className="px-md-0 mx-md-0" md={6} lg={4} xl={3}>
										<div
											style={{ border: "3px solid #EFF3F6" }}
											className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
										>
											<div className="text-center py-4">
												<StaticImage
													style={{ maxWidth: "100px" }}
													src="../images/SVG - Icon love heart.svg"
													quality={100}
													className=" mb-4 "
													placeholder="traced_svg"
												/>
												<h3 className="text-dark-bg fs-4">Service 1</h3>
											</div>
										</div>
									</Col>
									<Col className="px-md-0 mx-md-0" md={6} lg={4} xl={3}>
										<div
											style={{ border: "3px solid #EFF3F6" }}
											className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
										>
											<div className="text-center py-4">
												<StaticImage
													style={{ maxWidth: "100px" }}
													src="../images/SVG - Icon star.svg"
													quality={100}
													className=" mb-4 "
													placeholder="traced_svg"
												/>
												<h3 className="text-dark-bg fs-4">Service 2</h3>
											</div>
										</div>
									</Col>
									<Col className="px-md-0 mx-md-0" md={6} lg={4} xl={3}>
										<div
											style={{ border: "3px solid #EFF3F6" }}
											className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
										>
											<div className="text-center py-4">
												<StaticImage
													style={{ maxWidth: "100px" }}
													src="../images/SVG - Icon speach.svg"
													quality={100}
													className=" mb-4 "
													placeholder="traced_svg"
												/>
												<h3 className="text-dark-bg fs-4">Service 3</h3>
											</div>
										</div>
									</Col>
									<Col className="px-md-0 mx-md-0" md={6} lg={4} xl={3}>
										<div
											style={{ border: "3px solid #EFF3F6" }}
											className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
										>
											<div className="text-center py-4">
												<StaticImage
													style={{ maxWidth: "100px" }}
													src="../images/SVG - Icon book.svg"
													quality={100}
													className=" mb-4 "
													placeholder="traced_svg"
												/>
												<h3 className="text-dark-bg fs-4">Service 4</h3>
											</div>
										</div>
									</Col>
									<Col className="px-md-0 mx-md-0" md={6} lg={4} xl={3}>
										<div
											style={{ border: "3px solid #EFF3F6" }}
											className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
										>
											<div className="text-center py-4">
												<StaticImage
													style={{ maxWidth: "100px" }}
													src="../images/SVG - Icon box arrow.svg"
													quality={100}
													className=" mb-4 "
													placeholder="traced_svg"
												/>
												<h3 className="text-dark-bg fs-4">Service 5</h3>
											</div>
										</div>
									</Col>
									<Col className="px-md-0 mx-md-0" md={6} lg={4} xl={3}>
										<div
											style={{ border: "3px solid #EFF3F6" }}
											className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
										>
											<div className="text-center py-4">
												<StaticImage
													style={{ maxWidth: "100px" }}
													src="../images/SVG - Icon script.svg"
													quality={100}
													className=" mb-4 "
													placeholder="traced_svg"
												/>
												<h3 className="text-dark-bg fs-4">Service 6</h3>
											</div>
										</div>
									</Col>
									<Col className="px-md-0 mx-md-0" md={6} lg={4} xl={3}>
										<div
											style={{ border: "3px solid #EFF3F6" }}
											className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
										>
											<div className="text-center py-4">
												<StaticImage
													style={{ maxWidth: "100px" }}
													src="../images/SVG - Icon dot.svg"
													quality={100}
													className=" mb-4 "
													placeholder="traced_svg"
												/>
												<h3 className="text-dark-bg fs-4">Service 7</h3>
											</div>
										</div>
									</Col>
									<Col className="px-md-0 mx-md-0" md={6} lg={4} xl={3}>
										<div
											style={{ border: "3px solid #EFF3F6" }}
											className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
										>
											<div className="text-center py-4">
												<StaticImage
													style={{ maxWidth: "100px" }}
													src="../images/SVG - Icon pill.svg"
													quality={100}
													className=" mb-4 "
													placeholder="traced_svg"
												/>
												<h3 className="text-dark-bg fs-4">Service 8</h3>
											</div>
										</div>
									</Col>
								</Row>
								<Row>
									<Col className="text-center">
										{" "}
										<Button
											as={Link}
											to="/"
											className="px-3 me-md-4 w-100 w-md-auto mt-5 text-white "
											variant="primary"
										>
											All Services
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="text-center pb-3 pb-lg-7">
								<h2 className="text-dark-bg pb-4">Professional testimonials</h2>
								{/* <p>
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore fugiat sunt culpa officia deserunt mollit anim
									est laborum.
								</p> */}
							</Col>
						</Row>
						<Row className="gx-5 pt-5">
							{data.profReviews.nodes
								.filter((item, index) => index < 4)
								.map((review) => (
									<Col className="mb-5 mb-xl-0" xs={12} md={6} xl={3}>
										<ReviewCardProf review={review} />
									</Col>
								))}
						</Row>
					</Container>
				</section>
				{/* <section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="text-center pb-5 pb-lg-7">
								<h2 className="text-dark-bg pb-4">Information And Support</h2>
								<p>
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore fugiat sunt culpa officia deserunt mollit anim
									est laborum.
								</p>
							</Col>
						</Row>
						<Row className="gx-6 justify-content-center">
							{data.allWpSupportArticle.nodes.map((article) => (
								<Col className="mb-5 mb-xl-0" xs={12} lg={6} xl={4}>
									<div
										style={{
											borderRadius: "15px",
											overflow: "hidden",
											border: "3px solid #4568AA15",
										}}
									>
										<Row className="align-items-center">
											<Col className="px-0 mx-0" xs={6}>
												<GatsbyImage
													image={
														article.supportFields.image.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={article.supportFields.image.altText}
												/>
											</Col>
											<Col className="px-0 mx-0 bg-white" xs={6}>
												<div className="bg-white  px-4 ">
													<h2 className="text-dark-bg ubuntu-medium fs-5">
														{article.title}
													</h2>
													<Link
														className="link-secondary"
														to={article.supportFields.link}
													>
														Read More
													</Link>
												</div>
											</Col>
										</Row>
									</div>
								</Col>
							))}
						</Row>
						<Row>
							<Col className="text-center">
								<Button
									as={Link}
									to="/"
									className="px-3 me-md-4 w-100 w-md-auto text-white mt-lg-5"
									variant="primary"
								>
									All Resources
								</Button>
							</Col>
						</Row>
					</Container>
				</section> */}
			</Layout>
		</>
	);
};

export default IndexPage;
